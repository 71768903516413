import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/vitasis/vitasis_img1.png'
import img2 from '../../assets/images/vitasis/vitasis_img2.gif'
import img3 from '../../assets/images/vitasis/vitasis_img3.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionFullImage,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import { HeadlineM, TitleM, ParagraphL, ParagraphM, ParagraphS } from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'UX dizajn',
    'UI design',
    'Grafično oblikovanje',
    'UX dizajn',
    'UI design',
    'Grafično oblikovanje',
    'UX dizajn',
    'UI design',
    'Grafično oblikovanje',
    'UX dizajn',
    'UI design',
    'Grafično oblikovanje',
    'UX dizajn',
    'UI design',
    'Grafično oblikovanje',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Vitasis"
        description="Za podjetje Vitasis smo izdelali dizajn za nadgradnjo obstoječe aplikacije za tablične računalnike, ki je bila ustvarjena v pilotni fazi projekta. Nadgradili smo tudi obstoječo grafično podobo."
        image={img1}
      />
      <Header
        logoBackground={colors.blue}
        logoBorder={colors.blue}
        linkText={colors.blue}
        menuLines={colors.blue}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Vitabits App" />
          </div>
          <Square background={colors.blue} className="title">
            <ParagraphM color={colors.white}>Vitasis</ParagraphM>
            <HeadlineM color={colors.yellow}>Vitabits App</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.blue}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Oblikovati aplikacijo, ki bo enostavna za uporabo tudi uporabnikom starejšim od 50
              let.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Za podjetje Vitasis smo izdelali dizajn za nadgradnjo obstoječe aplikacije za tablične
              računalnike, ki je bila ustvarjena v pilotni fazi projekta. Nadgradili smo tudi
              obstoječo grafično podobo.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding reduce-bottom-padding">
            <Content>
              {/* Example Title */}
              <h2>O projektu</h2>

              {/* Example Paragraph */}
              <p>
                Aplikacija uporabnika ob prvi uporabi seznani z glavnimi funkcijami in prednostmi
                uporabe aplikacije s kratkimi teksti in ilustracijami. Uporabnik lahko enostavno
                preklaplja med svetlim in temnim načinom.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Vitasis" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-bottom-padding">
            <Content>
              {/* Example Paragraph */}
              <p>
                Izbrana barvna paleta ponazarja zdravje in deluje pomirjujoče. Večina uporabnikov
                aplikacije je starejših od 50 let, kar smo imeli v mislih tudi pri izboru
                tipografije in velikosti črk. Oblikovali smo set ikon, ki so pripomogle k temu, da
                je aplikacija bolj intuitivna in enostavna za uporabo.
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img3} alt="Vitasis" />
          </SectionFullImage>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText>
            <Content></Content>
          </SectionRichText>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/to-poletje-bo-nord">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/obcina-tolmin">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
